<template>
  <div class="container">
    <el-tabs v-model="activeNames" @tab-click="handleClick" type="card">
      <el-tab-pane :label="item.name" :name="item.id" v-for="(item, index) in data_list" :key="index"></el-tab-pane>
    </el-tabs>


        <el-dialog title="添加部门" :visible.sync="dialogVisible" width="30%">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="部门名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="上级部门">
              <span v-if="this.name != ' '">{{ this.name }}</span>
              <span v-if="this.name != ' '">
                <el-button type="text" @click="delete_name">重置</el-button>
              </span>
              <el-tree v-if="this.name == ' '" :data="data" @check-change="getCheckedNodes" show-checkbox
                check-strictly></el-tree>
              <!-- <el-select v-model="form.region" placeholder="请选择活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select> -->
            </el-form-item>
            <el-form-item label="责任人">
              <el-input v-model="form.region"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
              <el-button @click="dialogVisible = false">取消</el-button>

            </el-form-item>
          </el-form>
        </el-dialog>
        <div class="box">
          <el-scrollbar style="height:85vh;width: 20%;
            ">
            <div class="left" style="width: 100%
            ;">
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="dialogVisible = true">添加部门</el-button>
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="adduser">添加用户</el-button>
              <el-tree :data="data" @node-click="handleNodeClick" default-expanded-keys default-expand-all
                highlight-current></el-tree>
            </div>
          </el-scrollbar>
          <div style="width:80%" class="bbs">



            <div style="width: 100%;height: 40px;display: flex;">
              <div v-if="this.form_data_type == true && num == 0" style="width: 100%;height: 40px;display: flex;">
                <div>
                  部门名称: <el-input v-model="form_data.Department_name" style="width: 200px;height: 20px;"></el-input>
                </div>

                <div style="margin-left: 20px; ">
                  责任人: <el-input v-model="form_data.principal" style="width: 200px;height: 20px;"></el-input>
                </div>
                <div style="margin-left: 20px; ">
                  联系方式: <el-input v-model="form_data.contact" style="height: 20px;width: 200px"></el-input>
                </div>
                <div style="margin-left: 20px;">
                  <el-button type="primary" @click="onSubmits(1)" style="height:30px;line-height: 10px;">修改</el-button>

                  <el-button @click="onSubmits(2)" style="height:30px;line-height: 10px;">删除</el-button>
                </div>
              </div>
              <div v-if="num == 1" style="width: 100%;height: 40px;display: flex;">
                <div>
                  所属项目: <el-select v-model="value" placeholder="请选择" style="width: 200px;margin-right: 10px;">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>

                </div>
                <div>
                  用户名: <el-input v-model="input1" placeholder="请输入用户名"
                    style="width: 200px;margin-right: 10px;"></el-input>

                </div>
                <div>
                  密码: <el-input v-model="input2" placeholder="请输入密码" style="width: 200px;margin-right: 10px;"></el-input>
                </div>
                <div>
                  手机号:<el-input v-model="input3" placeholder="请输入手机号" style="width: 200px;margin-right: 10px;"></el-input>
                </div>
                <div>
                  <el-button @click="useraa" style="height:30px;line-height: 10px;" type="primary">添加</el-button>
                  <el-button style="height:30px;line-height: 10px;" type="primary" @click="addclear"> 添加&继续添加</el-button>
                </div>
              </div>
              <!-- <el-form
              ref="form"
              :model="form_data"
              label-width="80px"
              v-if="this.form_data_type == true"
            
            >
              <el-form-item label="部门名称">
                <el-input
                  v-model="form_data.Department_name"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="责任人">
                <el-input
                  v-model="form_data.principal"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系方式">
                <el-input
                  v-model="form_data.contact"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmits(1)">修改</el-button>
               
                <el-button @click="onSubmits(2)">删除</el-button>
             
              </el-form-item>
            </el-form> -->
            </div>

            <el-table :data="tableData" style="width: 100%" border height="85vh">
              <el-table-column prop="name" label="用户名"> </el-table-column>


              <el-table-column prop="position" label="部门"> </el-table-column>
              <el-table-column prop="rank" label="权限"> </el-table-column>
              <el-table-column prop="phone" label="手机号"> </el-table-column>
              <el-table-column label="操作" style="width: 400px">
                <template slot-scope="scope">


                  <el-button type="danger" @click="deleteUser(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>

          </div>
          <!-- <div class="right">
            <el-form
              ref="form"
              :model="form_data"
              label-width="80px"
              v-if="this.form_data_type == true"
            >
              <el-form-item label="部门名称">
                <el-input
                  v-model="form_data.Department_name"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="责任人">
                <el-input
                  v-model="form_data.principal"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系方式">
                <el-input
                  v-model="form_data.contact"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmits(1)">修改</el-button>
               
                <el-button @click="onSubmits(2)">删除</el-button>
             
              </el-form-item>
            </el-form>
          </div> -->
        </div>

  </div>
</template>

<script>
// var reg = /^1[3-9]\d{9}$/ .test()
import {
  getProjectInfo,
  show_Department,
  add_Department,
  get_show_Department,
  Department_up,
  Department_del,
  getUserInfo, deleteUserInfo, addUserInfo,
} from "../../../request/project";
import {
  personnelInformation
} from "../../../request/risk";
export default {
  data() {
    return {
      data_list: [],
      activeNames: '',
      input1: '',
      input2: '',
      input3: '',
      options: [],
      value: '',
      num: 0,//添加用户隐藏显示
      tableData: [],//表格数据
      dialogVisible: false,
      name: " ",
      name_id: "",
      activeName: "first",
      data: [],
      form: {
        name: "",
        region: "",
        phone: "",
      },
      form_data: {},
      form_data_type: false,
      posts: '',
      pjtid:'',

      //:props="defaultProps"
    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.getProject()
    this.personnelInformation()

    },
  },
  created() {
    // 获取所有公司
    // this.getcompany();
    this.getProject()
  },
  mounted() {
    this.personnelInformation()
  

  },
  methods: {
    //通过项目id获取人员信息
    async personnelInformation() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),
      }
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project') 
        }else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project') 
        }
      }
      const res = await personnelInformation(msg)
      console.log(res, '通过项目id获取人员信息');
      if (res.data.data.length > 0) {
        this.data_list = res.data.data
        this.activeNames = res.data.data[0].id
      }
      this.getcompany();


    },
    handleClick(tab, event) {
      this.getcompany();


      },

    async addclear() {
      var reg = /^1[3-9]\d{9}$/
      if (reg.test(this.input3)) {

        if (this.value != '' && this.input1 != '' && this.input2 != '') {
          let msg = {
            name: this.input1,
            password: this.input2,
            phone: this.input3,
            rank: 3,
            position: this.posts,
            corporation: this.value,
            company: localStorage.getItem("companys")
          }
          console.log(msg);
          let res = await addUserInfo(msg)
          console.log(res);
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.getUser()
          this.input1 = ''
          this.input2 = ''
          this.input3 = ''


          this.value = ''
        } else {
          this.$message({
            message: '信息未填写完整.请填写完整',
            type: 'warning'
          });
        }

      } else {
        this.$message({
          message: '请填写正确的手机号',
          type: 'warning'
        });
      }



    },
    async adduser() {
      if (this.posts != '') {
        this.num = 1

      } else {
        this.$message({
          message: '请先选择下方部门,然后再次点击添加用户',
          type: 'warning'
        });
      }
    },
    //添加
    async useraa() {
      console.log(this.value);
      var reg = /^1[3-9]\d{9}$/
      if (reg.test(this.input3)) {

        if (this.value != '' && this.input1 != '' && this.input2 != '') {
          let msg = {
            name: this.input1,
            password: this.input2,
            phone: this.input3,
            rank: 3,
            position: this.posts,
            corporation: this.value,
            company: localStorage.getItem("companys")
          }
          console.log(msg
          );
          let res = await addUserInfo(msg)
          console.log(res);
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.getUser()
          this.num = 0

        } else {
          this.$message({
            message: '信息未填写完整.请填写完整',
            type: 'warning'
          });
        }

      } else {
        this.$message({
          message: '请填写正确的手机号',
          type: 'warning'
        });
      }



    },
    async adduser() {
      if (this.posts != '') {
        this.num = 1

      } else {
        this.$message({
          message: '请先选择下方部门,然后再次点击添加用户',
          type: 'warning'
        });
      }
    },
    // 获取项目名称
    async getProject() {

      let msg = {
        //   name:this.name,  pro  1 项目   0 公司
        // corporation:this.corporation
        unique_identification: localStorage.getItem("uid"),
        name: "",
        phase: "",
        page: 1,
        size: 99999
      };
      const res = await getProjectInfo(msg);

      console.log(res.data.data, 66666);
      res.data.data.forEach(item => {
        this.options.push({
          value: item.id,
          label: item.name
        })
      });


      // console.log(this.tableData);
    },
    // 表格删除
    deleteUser(row) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let msg = {
            id: row.uid,
          };
          const res = await deleteUserInfo(msg);
          console.log(res);
          if (res.data.code == 200) {
            this.editUserDialogVisible = false;
            this.getUser();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取选中项目下的所有人员
    async getUser() {
      console.log(this.posts);
      this.tableData = [];
      let msg = {
        // corporation: this.corporation,
        corporation: this.activeNames,
        posts: this.posts
      };
      const res = await getUserInfo(msg);
      console.log(res);
      if (res.data.length == 0) {
        return;
      }
      this.tableData = res.data.data;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].rank == 0) {
          this.tableData[i].rank = "超级管理员";
        } else if (this.tableData[i].rank == 1) {
          this.tableData[i].rank = "公司管理员";
        } else if (this.tableData[i].rank == 2) {
          this.tableData[i].rank = "项目管理员";
        } else if (this.tableData[i].rank == 3) {
          this.tableData[i].rank = "普通用户";
        }
      }
    },
    async delete_name() {
      this.name = " ";
    },
    getCheckedNodes(leafOnly) {
      console.log(leafOnly);
      this.name = leafOnly.label;
      this.name_id = leafOnly.id;
    },
    getCheckedNodess(leafOnly) {
      console.log(leafOnly);
      this.name = leafOnly.label;
      this.name_id = leafOnly.id;
    },
    async onSubmit() {
      var msg = {
        pro_id: localStorage.getItem("corporations"),
        Department_name: this.form.name,
        superior_Department: this.name_id,
        principal: this.form.region,
        contact: this.form.phone,
      };
      if (this.form.name == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填写部门名称",
          type: "warning",
        });
        return false;
      }
      if (this.name == " ") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择上级部门",
          type: "warning",
        });
        return false;
      }
      if (this.form.region == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填写责任人",
          type: "warning",
        });
        return false;
      }
      if (this.form.phone == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填写联系方式",
          type: "warning",
        });
        return false;
      }
      const { data: res } = await add_Department(msg);
      console.log(res);
      this.getcompany();
      this.dialogVisible = false;
    },
    // 获取所有公司
    async getcompany() {
      var msg = {
        pro_id: this.activeNames,
      };
      const { data: res } = await show_Department(msg);
      console.log(res);
      this.data = res.data;
      // this.posts = res.data[0].label
    },
    async handleNodeClick(e, item, s) {
      console.log(e, '树结构');
      this.num = 0
      var msg = {
        id: e.id,
      };
      this.posts = e.label
      const { data: res } = await get_show_Department(msg);
      console.log(res.data[0],'11111111111111111111');
      this.form_data = res.data[0];
      if (res.data[0].superior_Department == 0) {
        this.form_data_type = false;
      }
      if (res.data[0].superior_Department != 0) {
        this.form_data_type = true;


        this.getUser()
      }





    },
    async onSubmits(e) {
      console.log(e);
      if (e == 1) {
        console.log("修改操作");
        if (this.form_data.Department_name == "") {
          this.$notify({
            title: "警告",
            message: "添加失败，你没有填写部门名称",
            type: "warning",
          });
          return false;
        }
        if (this.form_data.principal == "") {
          this.$notify({
            title: "警告",
            message: "添加失败，你没有填写责任人",
            type: "warning",
          });
          return false;
        }
        if (this.form_data.contact == "") {
          this.$notify({
            title: "警告",
            message: "添加失败，你没有填写联系方式",
            type: "warning",
          });
          return false;
        }
        const { data: res } = await Department_up(this.form_data);
        console.log(res);
        this.getcompany();

        this.form_data_type = false;
      }
      if (e == 2) {
        console.log("删除操作");
        const { data: res } = await Department_del(this.form_data);
        console.log(res);
        this.getcompany();
        this.form_data_type = false;
      }
    },
  },
};
</script>
<style scoped>
.box {
  display: flex;
  width: 100%;
  height: 100%;
}

.left {
  width: 20%;
}

.right {
  position: fixed;
  top: 40%;
  left: 40%;
  width: 70%;
}

.el-tree-node.is-current>.el-tree-node__content {
  background-color: #c2d6ea !important;
}
</style>
<style>
.bbs .el-input__inner {

  height: 30px;
  line-height: 30px;

}
</style>